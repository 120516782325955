@use "tailwindcss";
@plugin "daisyui" {
  themes: light --default, dark --prefersdark;
}


/* Base styles */
@layer base {
  html {
    font-family: 'Inter', system-ui, sans-serif;
  }

  body {
    @apply min-h-screen bg-base-100 text-base-content transition-colors duration-200;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold tracking-tight;
  }
/* View Transitions */
::view-transition-old(root),
::view-transition-new(root) {
  animation: none;
  mix-blend-mode: normal;
}

::view-transition-old(page) {
  animation: 90ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(page) {
  animation: 210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}

::view-transition-old(card),
::view-transition-new(card) {
  animation: none;
  mix-blend-mode: normal;
}

::view-transition-old(card) {
  animation: 300ms cubic-bezier(0.4, 0, 0.2, 1) both scale-down;
}

::view-transition-new(card) {
  animation: 300ms cubic-bezier(0.4, 0, 0.2, 1) both scale-up;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(30px);
  }
}

@keyframes slide-to-left {
  to {
    transform: translateX(-30px);
  }
}

@keyframes scale-up {
  from {
    transform: scale(0.95);
  }
}

@keyframes scale-down {
  to {
    transform: scale(0.95);
  }
}
}

/* Component styles */
@layer components {
  .card {
    @apply rounded-xl shadow-lg transition-all duration-200 hover:shadow-xl;
  }

  .btn {
    @apply transition-all duration-200 font-medium;

    &:active {
      transform: translateY(1px);
    }
  }

  .btn-primary {
    @apply transition-colors duration-200;
  }

  .input,
  .textarea,
  .select {
    @apply transition-all duration-200;

    &:focus {
      @apply outline-none ring-2 ring-primary ring-black/50;
    }
  }

  .stat-grid {
    @apply grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4;
  }

  .badge {
    @apply font-medium;
  }

  .loading {
    @apply text-primary;
  }
}

/* Utility styles */
@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
